
import Vue from "vue"
import { mapStores } from "pinia"
import { useCopilotStore } from "@/stores/copilot"
import TheSidebarItem from "@/components/TheSidebarItem.vue"

export default Vue.extend({
  name: "CopilotSidebarItem",
  components: {
    TheSidebarItem,
  },
  props: {
    dense: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapStores(useCopilotStore),
    iconStyle(): Record<string, unknown> {
      return {
        left: this.dense ? "-2px" : "2px",
      }
    },
  },
  methods: {
    getIconColor(isActive) {
      if (isActive) {
        return this.$vuetify.theme.currentTheme.primary
      }

      return this.$vuetify.theme.currentTheme.on_background
    },
  },
})

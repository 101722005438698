
import TheSidebarLoadingPlaceholder from "@/components/TheSidebarLoadingPlaceholder"
import ProjectListItem from "@/components/projects/ProjectListItem"
import TheSidebarItem from "@/components/TheSidebarItem"
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"
import { useProjectStore } from "@/stores/project"

export default {
  name: "TheSidebarProjectsList",
  components: {
    TheSidebarLoadingPlaceholder,
    TheSidebarItem,
    ProjectListItem,
  },
  props: {
    listStyle: {
      type: String,
      default: () => "",
    },
  },
  computed: {
    ...mapStores(useLayoutStore, useProjectStore),
  },
}


import { mapStores } from "pinia"
import { useNotificationStore } from "@evercam/shared/stores/notification"
import { SECOND } from "@evercam/shared/constants/schedules"

export default {
  name: "TheSnackbar",
  data() {
    return {
      defaultTimeout: 3 * SECOND,
    }
  },
  computed: {
    ...mapStores(useNotificationStore),
    timeout() {
      return this.notificationStore?.timeout || this.defaultTimeout
    },
    snackbarPosition() {
      return this.$device.isMobile ? "bottom" : "top"
    },
  },
}

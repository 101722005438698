
import Vue from "vue"
import { mapStores } from "pinia"
import { useCopilotStore } from "@/stores/copilot"
import BetaTag from "@evercam/shared/components/BetaTag.vue"

export default Vue.extend({
  name: "CopilotButton",
  components: {
    BetaTag,
  },
  props: {
    isIconOnly: {
      type: Boolean,
      default: false,
    },
    betaFeature: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFocused: false,
      isActive: false,
    }
  },
  computed: {
    ...mapStores(useCopilotStore),
    isHighlighted(): boolean {
      return this.isActive || this.isFocused
    },
    highlightColor(): string {
      return this.isHighlighted
        ? (this.$vuetify.theme.currentTheme.primary as string)
        : (this.$vuetify.theme.currentTheme.onBackground as string)
    },
  },
  methods: {
    handleClick() {
      this.copilotStore.toggleCopilot()
      this.isActive = !this.isActive
    },
    handleFocus() {
      this.isFocused = true
    },
    handleBlur() {
      this.isFocused = false
    },
  },
})
